.header {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 2;
}

.menuButton {
  padding-right: 1rem;
}

.deleteButton {
  margin: 1rem;
}
